.p-toast {
    width: 90vw;
    max-width: 450px;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
}

@media screen and (max-width: 480px) {
    .p-toast {
        width: 90%;
        max-width: none;
    }

    .p-toast .p-toast-message {
        border-radius: 0;
        margin: 0;
    }
}
