@import 'normalize.css';

.p-dialog {
    max-height: 90%;
}

:root {
    --main-gray: #d9d9d9;
    --main-red: #a23418;
    --link-red: #e44820;
    --main-black: #272727;
    --background-color: #FFFFFF;
    --main-white: #FFFFFF;
    --error-color: #D32F2F;
}

/* Custom global styles */
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    margin: 0;
    color: var(--main-black);
    background-color: var(--background-color);
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    line-height: 1.2;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

a {
    text-decoration: none;
    color: var(--main-red);
    transition: color 0.3s ease;
}

a:hover {
    color: var(--link-red);
}

.button,
.submit-button {
    background-color: var(--main-red);
    color: var(--background-color);
}

.button:hover,
.submit-button:hover {
    background-color: var(--link-red);
}

.error-message {
    text-align: end;
    color: var(--error-color);
    font-size: 0.75rem;
    margin: 0;
    margin-bottom: 0.5rem;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}