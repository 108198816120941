.footer {
    background-color: var(--main-gray);
    color: var(--main-black);
    padding: 35px 20px 20px;
}

.footer-desktop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1600px;
    margin: 0 auto;
}

.footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.component-title {
    text-align: center;
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-style: italic;
    margin-bottom: 25px;
}

.bottom-margin {
    margin-bottom: 5px;
}

.footer-copy {
    text-align: center;
    color: #777;
    font-size: 0.9rem;
}

.footer-links a {
    transition: color 0.3s ease;
}

.footer-links a:focus {
    outline: 2px solid var(--link-red);
    outline-offset: 2px;
}

@media (max-width: 844px) {
    .footer {
        padding: 25px 15px 15px;
    }

    .footer-section {
        margin-bottom: 25px;
        min-width: 50%;
    }

    .component-title {
        font-size: clamp(1.3rem, 2.5vw, 1.8rem);
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 1rem;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        margin-top: 1rem;
    }

    .footer-links a {
        margin: 0 0.5rem;
    }
}

@media (max-width: 480px) {
    .component-title {
        font-size: clamp(1.2rem, 2vw, 1.5rem);
        margin-bottom: 15px;
    }
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid var(--form-border-color);
    border-radius: 4px;
    transition: border-color 0.3s ease;
    resize: none;
}