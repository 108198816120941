.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
}

.error-page h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--main-red);
}

.error-page p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--main-black);
}

.error-details {
  font-style: italic;
  color: #666;
  margin-bottom: 2rem;
}

.back-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--main-red);
  color: var(--background-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: var(--link-red);
}