:root {
    --background-color: #f9f9f9;
    --card-background: #ffffff;
    --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.services-page {
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    color: var(--main-black);
    background-color: var(--background-color);
}

.our-services {
    margin-bottom: 4rem;
}

.services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    list-style-type: none;
    padding: 0;
}

.services-list li {
    background-color: var(--main-white);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.our-services h2 {
    color: var(--main-red);
    font-size: 2rem;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
}

.our-services h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--link-red);
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.service-item {
    background-color: var(--card-background);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    transition: transform 0.3s ease;
    text-align: center;
}

.service-item:hover {
    transform: translateY(-5px);
}

.service-item h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: var(--main-red);
}

.service-item p {
    font-size: 0.9rem;
    color: var(--text-color);
}

@media (max-width: 1024px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
    }
}