.layout {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  background-color: var(--background-color);
  padding-top: var(--header-height);
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: var(--main-black);
  color: var(--background-color);
  padding: 8px;
  z-index: 100;
  /* Skip link is hidden by default and becomes visible on focus for accessibility */
}

.skip-link:focus {
  top: 0;
}