@import './layout.css';

:root {
    --footer-height: 60px;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-container .main-content {
    flex: 1;
    padding-bottom: var(--footer-height);
    background-color: var(--background-color);
}