.nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em 1em 1em;
}

.nav-links ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.nav-links li {
    margin: 25px 0;
}

.nav-links a {
    text-decoration: none;
    color: var(--main-red);
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: var(--link-red);
}

.nav-social {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.nav-social a {
    margin: 0 10px;
    text-decoration: none;
    color: var(--main-red);
    font-size: 24px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.nav-social a:hover {
    color: var(--link-red);
    transform: scale(1.1);
}

.social-title {
    text-decoration: underline;
    margin-bottom: 10px;
    text-align: center;
}

.hours-of-operation {
    text-align: center;
    margin-bottom: 20px;
}

.hours-of-operation h3 {
    margin-top: 0;
    font-size: 1.2rem;
    text-decoration: underline;
}

.hours-of-operation p {
    margin-bottom: 5px;
}

@media (max-width: 480px) {
    .nav-links ul {
        flex-direction: column;
        align-items: center;
    }

    .nav-links li {
        margin: 15px 0;
    }

    .hours-of-operation {
        font-size: 0.9rem;
    }

    .footer-copy {
        font-size: 0.8rem;
    }
}