.center {
    display: flex;
    text-align: center;
    font-size: clamp(1rem, 3vw, 1.5rem);
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
}

.center h1 {
    font-size: 1em;
    margin: 0.25em 0;
}

.center p {
    margin: 0;
}

.center a {
    color: var(--main-red);
    font-size: clamp(0.8rem, 2vw, 1rem);
    transition: color 0.3s ease;
}

.center a:hover {
    color: var(--link-red);
}

.photo {
    max-height: 150px;
    width: auto;
    transition: max-height 0.3s ease;
}

.details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    max-width: 1200px;
    margin: 0 auto;
}

.details-container button {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: var(--background-color);
    background-color: var(--main-red);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.details-container button:focus {
    outline: 2px solid var(--link-red);
    outline-offset: 2px;
}

.details-container button:hover {
    background-color: var(--link-red);
    color: var(--background-color);
}

.icon {
    margin-right: 10px;
}

.contact-links {
    width: auto;
}

.contact-links h3 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
}

.contact-links a {
    display: flex;
    align-items: center;
    color: var(--main-red);
    text-decoration: none;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.contact-links a:hover {
    color: var(--link-red);
}

@media (max-width: 844px) {
    .details-container {
        padding: 0.5em 1em;
    }
}

@media (max-width: 504px) {
    .details-container {
        padding: 0.5em 0.75em;
    }

    .contact-links h3 {
        text-align: start;
        font-size: 1rem;
    }

    .contact-links a {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .details-container {
        padding: 0.5em;
    }

    .details-container button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .photo {
        max-height: 120px;
    }
}