.invoice-form {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.invoice-form .p-field {
  margin-bottom: 1.5rem;
}

.invoice-form .p-card {
  margin-bottom: 1.5rem;
}

.invoice-form .p-fieldset {
  margin-bottom: 1.5rem;
}

.invoice-form .p-float-label {
  margin-bottom: 1rem;
}

.invoice-form .p-button-lg {
  margin-top: 1.5rem;
}

.invoice-form .p-grid.p-formgrid {
  margin-bottom: 1rem;
}

.invoice-form .p-d-flex.p-jc-between {
  margin-top: 0.5rem;
}

.delete-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.invoice-form .p-dropdown {
  width: 100%;
}

.invoice-form .p-inputnumber {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .invoice-form .p-card {
    padding: 1rem;
  }

  .invoice-form .p-fieldset {
    padding: 0.5rem;
  }

  .invoice-form .p-float-label {
    margin-bottom: 1rem;
  }

  .invoice-form .p-button-lg {
    width: 100%;
  }

  .invoice-form .p-grid.p-formgrid {
    flex-direction: column;
  }

  .invoice-form .p-col-12,
  .invoice-form .p-col-6 {
    width: 100%;
    padding: 0.5rem 0;
  }

  .invoice-form .p-d-flex.p-jc-between {
    flex-direction: column;
  }

  .invoice-form .p-d-flex.p-jc-between .p-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .delete-button {
    justify-content: center;
    margin-top: 0.5rem;
  }

  .invoice-form .p-dropdown {
    max-width: 100%;
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.invoice-form .react-select__control {
  border: 1px solid var(--surface-border);
  border-radius: 3px;
  background: var(--surface-ground);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.invoice-form .react-select__control:hover {
  border-color: var(--primary-color);
}

.invoice-form .react-select__control--is-focused {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color-light);
  border-color: var(--primary-color);
}

.invoice-form .react-select__menu {
  background: var(--surface-overlay);
  color: var(--text-color);
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.invoice-form .react-select__option {
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.invoice-form .react-select__option--is-focused {
  background-color: var(--primary-color-light);
}

.invoice-form .react-select__option--is-selected {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

@media screen and (max-width: 768px) {
  .invoice-form .react-select__menu {
    width: 100%;
    max-width: 100vw;
  }

  .invoice-form .react-select__option {
    white-space: normal;
    word-break: break-word;
  }
}